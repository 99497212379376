<template>
    <div>
        <WritersList />
    </div>
</template>

<script>
export default {
  name: 'AllWriters',
  components: {
    WritersList: () => import('@/modules/Writers/components/writersList'),
  },
  data() {
    return {}
  },
}
</script>